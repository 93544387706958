<template>
  <div 
    id="app"
    :class="['app ' + $route.name]"
  >
    <transition name="fade">
      <div 
        v-if="pageLoaded"
        id="page" 
        class="page" 
      >
        <transition name="fade">
          <Menu 
            v-if="menuVisible" 
            :is-auth="isAuth" 
            :cart-contents="cartContents" 
            :currency-code="currencyCode" 
            @changeCurrency="changeCurrency"
          />
        </transition>
        <div 
          :class="['mob menu-icon', {'active': menuVisible}]" 
          @click="toggleMenu" 
        >
          <span />
          <span />
          <span />
        </div>
        <div class="header-container">
          <Header 
            :app-balance="appBalance"
            :is-auth="isAuth" 
            :cart-contents="cartContents" 
            :currency-code="currencyCode" 
            @setSearch="setSearch"
            @changeCurrency="changeCurrency" 
            @setProductCategory="setProductCategory"
          />
        </div>
        <router-view 
          :app-search="appSearch"
          :app-balance="appBalance"
          :is-auth="isAuth" 
          :add-to-cart-chosen-item="addToCartChosenItem" 
          :currency="currency" 
          :product-category="productCategory" 
          :class-options="classOptions"
          :text-page-header="textPageHeader"
          :text-page-content="textPageContent"
          :currency-code="currencyCode"
          :error="error"
          @setAppBalance="setAppBalance"
          @addToCart="addToCart" 
          @formatDate="formatDate" 
          @goToProduct="goToProduct" 
          @login="login" 
          @forgotPass="forgotPass" 
          @registration="registration" 
          @setProductCategory="setProductCategory" 
        />
        <div class="footer-container">
          <Footer
            v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')"
          />
        </div>
        <transition name="fade">
          <Login 
            v-if="signInModalIsVisible" 
            @closeSignIn="closeSignIn"
            @openSignUp="openSignUp" 
            @login="login" 
            @forgotPass="forgotPass"
          />
        </transition>
        <transition name="fade">
          <Registration 
            v-if="signUpModalIsVisible" 
            @closeSignUp="closeSignUp" 
            @openSignIn="openSignIn" 
            @registration="registration" 
          />
        </transition>
        <transition name="fade">
          <Cart 
            v-if="cartIsVisible" 
            @setCartError="setCartError"
          />
        </transition>
        <transition name="fade">
          <ProductModal v-if="productModalIsVisible" />
        </transition>
        <transition name="fade">
          <TopUpModal
            v-if="topupModalIsVisible"
            @setTopUpError="setTopUpError"  
          />
        </transition>
        <div 
          v-if="cookesModalIsVisible"
          class="modal-overlay" 
        >
          <div class="cookies-modal">
            <div class="title">
              {{ $t('About Cookies') }}
            </div>
            <div class="desc">
              We employ cookies and similar technologies to enhance the functionality of our website, providing you with a seamless and personalized browsing experience. Some cookies are essential for basic functionality and are set automatically without requiring explicit consent.
              <br><br>
              In addition to essential cookies, we may offer optional cookies and similar technologies that bring various benefits, further enhancing your interaction with our website. As these are non-essential, their usage depends on your preferences. For more information, please refer to our 
              <a 
                class="desc"
                @click="goToPage('cookie')" 
              >
                {{ $t('Cookie Policy') }}
              </a>.
            </div>
            <button 
              class="button blue" 
              @click="acceptCookies"
            >
              <span>{{ $t('Accept') }}</span>
            </button>
            <button 
              class="button" 
              @click="declineCookies"
            >
              <span>{{ $t('Decline') }}</span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import {loadScriptsFromString} from "@/utils/load_scripts";
import Login from './components/Login.vue'
import Registration from './components/Registration.vue'
import Cart from './components/Cart.vue'
import TopUpModal from './components/TopUp.vue'
import ProductModal from './components/ProductModal.vue'
import {mapActions, mapState} from 'vuex';



export default {
  name: 'App',
  components: {
    Header,
    Menu,
    Footer,
    Registration,
    Login,
    Cart,
    TopUpModal,
    ProductModal
  },
  data: function() {
    return {
     topUpError: '',
     regIsAvailable: false,
     pageLoaded: false,
     menuVisible: false,
     nav: [
      {
        'title': 'HOME',
        'link': '/home'
      },
      {
        'title': 'C2 MARKET',
        'link': '/product-list'
      }
     ],
      isAuth: false,
      error: '',
      successMessage: '',
      orderHistory: [],
      orderToView: [],
      addToCartChosenItem: '',
      recommendedProducts: [],
      saleProducts: [],
      cartContents: [],
      totalSum: '0.00',
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      productPageItem: '',
      similarProducts: '',
      imgDomain: '',
      textPageList: '',
      productCategory: '',
      hostname: '',
      footerRequisites: '',
      footerSupportEmail: '',
      footerCopyright: '',
      categoryOptions: [],
      classOptions: '',
      currency: '',
      currencyCode: '',
      currencyOptions: '',
      signInModalIsVisible: false,
      signUpModalIsVisible: false,
      cartIsVisible: false,
      topupModalIsVisible: false,
      depositHistory: [],
      cookesModalIsVisible: false,
      socials: '',
      productModalIsVisible: false,
      appBalance: '',
      productClass: '',
      cartError: '',
      appSearch: '',
      showSelectSort: true
    }
  },
  computed: {
    ...mapState({
      currentLanguage: state => state.app.currentLanguage,
    }),
  },
  watch: {
    currencyCode() {
      if (this.productPageItem) {
        this.getProductPageContent(this.productPageItem.id);
      }
      if (this.isAuth) {
        this.orderHistory = '';
        this.cartContents = '';
        this.totalSum = '';
        this.getBalance();
        this.getOrderHistory();
        this.getDepositHistory();
        this.getCartContents();
      }
      
    },
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        if (this.$route.name == 'Profile') {
          this.getOrderHistory();
          this.getDepositHistory();
        }
        
      }
    }
  },
  mounted: async function() {
    this.cookiesModal();
    this.hostname = this.getDomainName(window.location.hostname);
    await this.fetchLanguages();
    await this.getCurrencies();
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();

    self.getGoogleTagManagerSnippet();
    
    self.$http.get(process.env.VUE_APP_API + 'is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          if (this.$route.name == 'Profile') {
            this.getOrderHistory();
            this.getDepositHistory();
          }
          self.getCartContents();
        }
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.openSignIn()
      }
    })
    this.getCategoryOptions();
    this.getTextPages();
    this.getSocials();
  },
  methods: {
    resetSelect() {
      let self = this;
      this.showSelectSort = false;
      setTimeout(function() {
        self.showSelectSort = true;
      }, 100)
    },
    setSearch(val) {
      let self = this;
      if (this.$route.name != "ProductListPage") {
        this.$router.push({ name: "ProductListPage" });
      }
      setTimeout(()=> {
        self.appSearch = val;
      }, 500)
    },
    removeProductClass() {
      this.productClass = '';
    },
    getBalance() {
      if (this.isAuth) {
        this.$http.get(process.env.VUE_APP_API + "user/profile?currency=" + this.currencyCode)
        .then((res) => {
            this.appBalance = res.data.balance;
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.openSignIn();
          }
        })
      }
    },
    setAppBalance(balance) {
      this.appBalance = balance;
    },
    cookiesModal() {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');

      if (!cookiesAccepted) {
          this.cookesModalIsVisible = true;
      }
    },
    declineCookies() {
      this.cookesModalIsVisible = false;
    },
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', 'true');
      this.cookesModalIsVisible = false;
    },
    getCurrencies() {
      this.$http.get(process.env.VUE_APP_API + 'currencies')
      .then((res) => {
        const newCurrencies = res.data.payload.map(({
          code: text,
          value: id,
          symbol
        }) => ({
          text,
          id,
          symbol
        }));
        this.currencyOptions = newCurrencies;

        if (localStorage.getItem("currency")) {
          this.currencyCode = localStorage.getItem("currency")
        } else {
          this.currencyCode = this.currencyOptions[0].text;
        }
        this.checkCurrency();
        
      })
      .catch(() => {
       
      })
    },
    changeCurrency(item) {
      this.currencyCode = item;
      this.checkCurrency();
    },
    checkCurrency() {
      this.currency = this.currencyOptions.find((item) => item.text == this.currencyCode).symbol;
    },
    setProductCategory(category) {
      let self = this;
      if (category) {
        this.$router.push({path: '/product-list'});
        this.productClass = category;
      }
    },
    getCategoryOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data?category=tf2')
      .then((res) => {
        this.categoryOptions = res.data.payload.types;
        this.classOptions = res.data.payload.classes;

      })
      .catch(() => {
       
      })
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    clearError() {
      this.error = '';
    },
    setError(error) {
      this.error = error;
    },
    setTopUpError(error) {
      this.topUpError = error;
    },
    setCartError(error) {
      this.cartError = error;
    },
    
    submitLoginSteam() {
      window.location.replace("https://api.marketofskins.com/auth/steam/redirect");
    },
    login(data) {
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.isAuth = true;
          this.getOrderHistory();
          this.getDepositHistory();
          this.getCartContents();
          this.closeSignIn();
          this.$router.push({path: '/profile/settings'});
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            self.isAuth = true;
            this.closeSignUp();
            self.$router.push({path: '/profile/settings'});
          } else {
            this.error = res.data.message
          }
        })
        .catch((res) => {
            this.error = res.response.data.message;
        })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        if (this.$route.name != 'Home') {
          this.$router.push({ path: '/' })
        }
        this.isAuth = false;
        this.$router.push({path: '/'});
      })
      .catch((res) => {
        if(res.response.status === 401) {
          console.log(res);
        }
      })
    },
    getDepositHistory() {
      this.$http.get(process.env.VUE_APP_API + 'deposit/history')
      .then((res) => {
        console.log(res)
        this.depositHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'purchase/history')
      .then((res) => {
        console.log(res)
        this.orderHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    removeOneFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id,
        'count': '1'
      }
      if (item.count > 1) {
        cartItem = {
          'id': id,
          'count': item.count - 1
        }
      }
      this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
      .then((res) => {
        self.cartContents = res.data.cart;
        this.getCartContents();
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
      
    },
    removeFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id
      }

      this.$http.post(process.env.VUE_APP_API + 'cart/remove', cartItem)
      .then((res) => {
        self.cartContents = res.data.cart;
        this.getCartContents();
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    addToCart(id) {
      if (!this.isAuth) {
          this.closeProductModal()
          this.openSignIn()
      } else {
        let self = this;

        let cartItem = {
          'id': id,
          'count': 1
        }

        for(var i = 0; i < this.cartContents.length; i++) {
          if (this.cartContents[i].item_id == id) {
              let cartItemCount = this.cartContents[i].count;
              cartItem = {
                'id': id,
                'count': cartItemCount + 1
              }
              break;
          }
        }

        this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
        .then((res) => {
          this.error = '';
          this.getCartContents();
          self.addToCartChosenItem = cartItem.id;
          self.cartContents = res.data.cart
          setTimeout(()=> {
            self.addToCartChosenItem = '';
          }, 2000)
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.openSignIn();
          } else {
            this.error = res.response.data.message;
          }
        })
      }
      
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents')
      .then((res) => {
          this.cartContents = res.data.cart;
          
          let total = 0;
          for (let i in this.cartContents) {
            let newPrice;
            if (this.cartContents[i].count > 1) {
              newPrice = this.cartContents[i].count * parseFloat(this.cartContents[i].item.price);
            } else {
              newPrice = parseFloat(this.cartContents[i].item.price);
            }
            total += newPrice;
          }
          this.totalSum = total.toFixed(2);
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    goToProduct(item) {
      this.openProductModal();
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.productPageItem = '';
      this.similarProducts = '';
      this.$http.get(process.env.VUE_APP_API + 'items/' + id)
      .then((res) => {
        this.productPageItem = res.data.payload;
        this.similarProducts = res.data.payload.similar;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    goToPage(item) {
      let newItem;
      if (item == "privacy") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].is_privacy) {
            newItem = this.textPageList[i].id;
          }
        }
      } else if (item == "terms") {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].is_terms) {
            newItem = this.textPageList[i].id;
          }
        }
      } else {
        newItem = item;
      }
      this.$router.push({
        path: "/pages/" + newItem,
        params: { data: newItem },
      });
      this.getTextPageContent(newItem);
      this.closeSignUp();
      this.closeTopup();
    },
    getTextPageContent(id) {
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';
      this.$http.get(process.env.VUE_APP_API + 'static-pages/' + id)
      .then((res) => {
        this.textPageTitle = res.data.payload.title;
        this.textPageContent = res.data.payload.content;
        this.textPageHeader = res.data.payload.header;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch((res) => {
         if(res.response.status === 401) {
          this.openSignIn()
        }
      })
    },
    openTopup() {
      this.topupModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeTopup() {
      this.topupModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openCart() {
      this.cartIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeCart() {
      this.cartIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openSignIn() {
      this.signUpModalIsVisible = false;
      this.signInModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeSignIn() {
      this.signInModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openSignUp() {
      this.signInModalIsVisible = false;
      this.signUpModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeSignUp() {
      this.signUpModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openProductModal() {
      this.productModalIsVisible = true;
      document.body.style.overflow = "hidden";
    },
    closeProductModal() {
      this.productModalIsVisible = false;
      document.body.style.overflow = "auto";
      this.error = '';
    },
    openRecover() {
      this.$router.push({path: '/recover'});
    },
    getGoogleTagManagerSnippet() {
      this.$http.get(process.env.VUE_APP_API + 'settings')
      .then((res) => {
        this.footerRequisites = res.data.payload.requisites;
        this.footerSupportEmail = res.data.payload.support_email;
        this.footerCopyright = res.data.payload.copyright;
        this.regIsAvailable = res.data.payload.is_reg_enabled;
        const snippet = res.data.payload.google_tag;
        if (snippet) {
          loadScriptsFromString(snippet);
        }
      })
      .catch((error) => {
        console.error("Error fetching Google Tag Manager snippet:", error);
      });
    },
    getSocials() {
      this.$http.get(process.env.VUE_APP_API + 'social-links')
      .then((res) => {
        this.socials = res.data.payload;
      })
      .catch((error) => {
        console.error(error);
      });
    },
    ...mapActions('app', ['fetchLanguages'])
  },
}
</script>
