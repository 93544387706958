<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="page-inside-top">
          <div class="wrapper">
            <div class="title">
              <router-link 
                to="/" 
                class="desc back"
              >
                {{ $t('Back to main') }}
              </router-link>
              {{ $t('Buy skins') }}
            </div>
          </div>
        </div>
        <div class="page-inside-wrapper">
          <div class="wrapper">
            <!-- <div class="category-list">
              <div 
                :class="['filter-button', {'active': filtersIsVisible}]" 
                @click="toggleFilter"
              >
                <img 
                  src="./../img/filterIcon.svg" 
                  class="filter-icon"
                >
                <div class="desc">
                  <b>
                    {{ $t('Filter') }}
                  </b>
                </div>
              </div>
              <Categories @setProductCategory="setProductCategory" />
            </div> -->
            <div class="bar">
              <div class="left">
                <div 
                  :class="['filter-button', {'active': filtersIsVisible}]" 
                  @click="toggleFilter"
                >
                  <img 
                    src="./../images/filter.svg" 
                    class="filter-icon"
                  >
                  <div class="desc">
                    <b>
                      {{ $t('Filter') }}
                    </b>
                  </div>
                </div>
                <a 
                  class="desc reset" 
                  @click="resetFilters"
                >
                  {{ $t('Reset all filters') }}
                </a>
              </div>
              <div class="desc">
                {{ totalProducts }} {{ $t('items') }}
              </div>
              <Select2 
                v-if="$parent.showSelectSort" 
                ref="select2"
                v-model="sort"
                class="select2 single currency-select price-select" 
                :options="sortOptions"
                :placeholder="$t('Highest price first')"
                @select="selectSort($event)"
              />
            </div>
            <div class="product-container">
              <transition name="slide2">
                <div 
                  v-show="filtersIsVisible"
                  :class="['top', {'not-full': !filtersIsVisible}]"
                >
                  <div class="filters">
                    <div class="filters-wrapper">
                      <div :class="['filters-block filters-price', {'active': priceFilterIsVisible}]">
                        <div 
                          class="desc"
                          @click="priceFilterIsVisible = !priceFilterIsVisible"
                        >
                          <b>{{ $t('Price') }}</b>
                        </div>
                        <div class="val-container">
                          <div class="val min">
                            <div class="desc desc-name">
                              {{ $t('From') }}
                            </div>
                            <div class="desc">
                              {{ currency }}
                            </div>
                            <input 
                              v-model="barMinValue"
                              type="number" 
                            >
                          </div>
                          <div class="val max">
                            <div class="desc desc-name">
                              {{ $t('To') }}
                            </div>
                            <div class="desc">
                              {{ currency }}
                            </div>
                            <input 
                              v-model="barMaxValue"
                              type="number" 
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="classOptions && classOptions.length"
                        :class="['filters-block filters-quality', {'active': classFilterIsVisible}]"
                      >
                        <div 
                          class="desc" 
                          @click="classFilterIsVisible = !classFilterIsVisible"
                        >
                          <b>{{ $t('Class') }}</b>
                        </div>
                        <label 
                          v-for="(item, i) in classOptions" 
                          :key="i"
                          class="chekbox-label" 
                        >
                          <div class="chekbox-label-wrapper">
                            <input 
                              v-model="classs"
                              type="checkbox" 
                              :value="item" 
                              @input="selectClass"
                            >
                            <div class="checkbox" />
                            <span class="title">{{ $t(item) }}</span>
                          </div>
                        </label>
                      </div>
                      <div
                        v-if="newCategoryOptions && newCategoryOptions.length"
                        :class="['filters-block filters-quality', {'active': qualityFilterIsVisible}]"
                      >
                        <div 
                          class="desc" 
                          @click="qualityFilterIsVisible = !qualityFilterIsVisible"
                        >
                          <b>{{ $t('Type') }}</b>
                        </div>
                        <label 
                          v-for="(item, i) in newCategoryOptions" 
                          :key="i"
                          class="chekbox-label" 
                        >
                          <div class="chekbox-label-wrapper">
                            <input 
                              v-model="category"
                              type="checkbox" 
                              :value="item" 
                            >
                            <div class="checkbox" />
                            <span class="title">{{ $t(item) }}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <transition name="fade">
                <div 
                  v-if="productList && productList.length"
                  :class="['list products', {'full': !filtersIsVisible}]"
                >
                  <div 
                    v-for="(item, i) in productList" 
                    :key="i"
                    class="item" 
                  >
                    <ProductCard 
                      :error="error"
                      :categories="[]" 
                      :item="item" 
                      :add-to-cart-chosen-item="addToCartChosenItem" 
                      :slider-is-clickble="true" 
                      :currency="currency" 
                      @goToProduct="goToProduct" 
                      @addToCart="addToCart"
                    />
                  </div>
                </div>
              </transition>
              <transition name="fade">
                <div
                  v-if="productList && !productList.length"
                  class="title small no-products" 
                >
                  {{ $t('No products!') }}
                </div>
              </transition>
            </div>
            <pagination 
              v-if="totalProducts"
              v-model="activePage" 
              :records="totalProducts" 
              :per-page="24" 
              @paginate="changePage"
            />
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Pagination from 'vue-pagination-2';
import ProductCard from '../components/ProductCard.vue';
// import Categories from '../components/Categories.vue';
import Select2 from 'vue3-select2-component';
import {mapState} from 'vuex';

export default {
  name: 'ProductListPage',
  components: {
    Pagination,
    ProductCard,
    // Categories,
    Select2
  },
  props: {
		addToCartChosenItem: {
			type: [String, Object, Number],
			required: true
		},
    appSearch: {
			type: String,
			required: true
		},
    error: {
			type: String,
			required: true
		},
		currency: {
			type: String,
			required: true
		},
		productCategory: {
			type: String,
			required: true
		},
    classOptions: {
			type: [String, Array],
			required: true
		},
    currencyCode: {
			type: String,
			required: true
		}
	},
  data: function() {
    return {
      imgDomain: '',
      filtersIsVisible: false,
      sort: 'desc',
      category: [],
      classs: [],
      activePage: 1,
      totalProducts: null,
      sortOptions: [],
      barMinValue: 0,
      barMaxValue: 1200,
      productList: '',
      classFilterIsVisible: true,
      qualityFilterIsVisible: true,
      priceFilterIsVisible: true,
      search: ''
    }
  },
  computed: {
    newCategoryOptions() {
      let newCategories = [...this.$parent.categoryOptions];
      return newCategories;
    },
    ...mapState({
      currentLanguage: state => state.app.currentLanguage,
    }),
  },
  watch: {
    barMinValue: function() {
      this.activePage = 1;
      this.filterProducts();
    },
    barMaxValue: function() {
      this.activePage = 1;
      this.filterProducts();
    },
    productCategory: function() {
      this.activePage = 1;
      this.filterProducts();
    },
    category: function() {
      this.activePage = 1;
      this.filterProducts();
    },
    currencyCode() {
      this.filterProducts();
    },
    appSearch(newVal) {
      this.search = newVal;
    },
    search() {
      this.activePage = 1;
      this.filterProducts();
    },
    currentLanguage() {
      let self = this;
      self.sortOptions = [];
      setTimeout(function() {
        self.sortOptions = [
          {
            id: 'desc',
            text: self.$t('Highest price first')
          }, 
          {
            id: 'asc',
            text: self.$t('Lowest price first')
          }
        ]
      }, 100)
    }
  },
  mounted() {

    this.sortOptions = [
      {
        id: 'desc',
        text: this.$t('Highest price first')
      }, 
      {
        id: 'asc',
        text: this.$t('Lowest price first')
      }
    ]
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.filterProducts();
    if (this.$parent.productClass) {
      this.classs = [this.$parent.productClass];
      this.$parent.removeProductClass();
      this.filterProducts();
    }
  },
  methods: {
    selectSort(event) {
      this.activePage = 1;
      this.sort = event.id;
      this.filterProducts();
    },
    selectCategory(event) {
      this.activePage = 1;
      this.category = event.text;
      this.$parent.setProductCategory(event.text);
      this.filterProducts();
    },
    selectClass() {
      setTimeout(() => {
        this.activePage = 1;
        this.filterProducts();
      }, 200);
    },
    resetFilters() {
      this.category = [];
      this.classs = [];
      this.barMinValue = 0;
      this.barMaxValue = 1200;
      this.filterProducts();
    },
    toggleFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {
      let addCategory = '';
      if (this.productCategory == 'search') {
        addCategory = 'type=';
      } else if (this.category == 'All types') {
        addCategory = 'type=';
      } else if (this.productCategory) {
        addCategory = 'type=' + this.productCategory
      } else {
        addCategory = 'type=' + this.category
      }

      let addClasses = '';
      if (this.classs != '') {
        addClasses = '&item_class=' + this.classs
      } else {
        addClasses = '';
      }
      let addPrice = '&price_from=' + this.barMinValue + '&price_till=' + this.barMaxValue;

      let addSort = '&sort=' + this.sort

      let addPage = '&page=' + this.activePage
      
      if (this.productCategory != '1') {

        let addCurrency = '';
        addCurrency = '&currency=' + this.$parent.currencyCode;
        
        this.$http.get(process.env.VUE_APP_API + 'items/list?' + addCategory + addClasses + addPrice + addSort + addPage + addCurrency + '&limit=24&category=tf2' + '&search=' + this.search)
        .then((res) => {
          this.productList = res.data.payload
          this.totalProducts = res.data.meta.total
        })
        .catch(() => {
         
        })
      }
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    addToCart(id) {
      this.$emit('addToCart', id)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
  }
}
</script>